.container {
    width: 400px;
    height: 400px;
}

.echarts-for-react {
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
}