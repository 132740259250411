:root {/* Colors: */

    --primary:#0B407D;
    --secondary:#D2E7FF;
    --tertiary:#E18600;
    --success:#029C48;
    --warning:#FF9800;
    --error:#D72F2F;
    --info:#333333;
    --white: #FFFFFF;
    --black: #282828;
    --disabled: #CDCDCD;
    --light-gray: #666666;
    --primary-gray:#F9F9FB;

    --primary-text:#FFFFFF;
    --secondary-text:#333333;
    --tertiary-text:#FFFFFF;
    --success-text:#FFFFFF;
    --warning-text:#FFFFFF;
    --error-text:#FFFFFF;
    --info-text:#333333;
    --white-text: #FFFFFF;
    --black-text: #282828;
    --disabled-text: #999999;

    /* Font/text values */
    --font-family-barlow: Barlow;
    --font-style-normal: normal;
    --font-weight-bolder: 700;
    --font-weight-bold: 600;
    --font-weight-semibold: 500;
    --font-weight-normal: normal;


    --font-size-xxs: 10px;
    --font-size-xs: 12px;
    --font-size-sm: 16px;
    --font-size-md: 20px;
    --font-size-lg: 24px;
    --font-size-xl: 28px;
    --font-size-xxl: 36px;
    --font-size-xxxl: 40px;
    --character-spacing-0: 0px;
    --line-spacing-18: 18px;
    --line-spacing-20: 20px;
}
