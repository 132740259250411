.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 50px 200px;
    cursor: pointer;
}

input[type="file"], .gjs-sm-sector__general, .gjs-sm-sector__extra {
    display: none;
}

.gjs-sm-header {
    display: none !important;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    font-size: 20px;
    z-index: 17;
}

.gjs-sm-icon {
    color: var(--black) !important;
    font-size: 13px;
    font-weight: 400 !important;
}

.sidebar {
    height: 87%;
    width: 325px;
    position: fixed;
    z-index: 5;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    margin-top: 97px;
    margin-bottom: 20px;
}

.cursor {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

.menu-icon {
    color: var(--light-gray);
}

.active-link .menu-item {
    /*background-color: var(--secondary);*/
    background-color: #D1DBE6;
    position: relative;
    border-radius: 7px;
}

.active-link .menu-icon {
    color: var(--primary);
}

.active-link span {
    color: var(--primary) !important;
    font-size: 18px;
    font-weight: 600;
}

.left-panel-submenus .MuiAccordion-root {
    background-color: #E7ECF2;
}

.left-panel-submenus .MuiSvgIcon-root {
    width: 25px;
    fill: #888c9f;
}

.left-panel-submenus .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd {
    position: absolute;
    right: 12px;
}

.avatar-img {
    width: 34px !important;
    height: 34px !important;
    object-fit: cover;
    font-size: 14px !important;
    font-family: 'Poppins', sans-serif;
}

@-webkit-keyframes apploader {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes apploader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dl-app-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    border: 0;
    width: 100%;
    z-index: 9999;
}

.dl-app-loader {
    border: 10px solid #073f61;
    border-radius: 50%;
    border-top: 10px solid #f3f3f3;
    width: 80px;
    height: 80px;
    opacity: 0.8;
    -webkit-animation: apploader 2s linear infinite; /* Safari */
    animation: apploader 2s linear infinite;
    z-index: 9999;
}

.border-bottom-dashed {
    border-bottom: 2px dashed #e4e6ef;
}

.list-item-hover:hover {
    background-color: #f4f9fc;
}

.page-wrapper-container {
    min-height: calc(100vh - 75px);
}

.page-wrapper-container-full {
    min-height: 100vh;
}

.dl-screen-title {
    color: var(--primary-text);
    font-weight: 600;
    font-size: 17px;
}

.table-head {
    color: rgb(161, 165, 183) !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    font-family: 'Poppins', sans-serif !important;
}

.border-rounded {
    border-radius: 6px;
}

.draggable-content > div {
    display: contents;
}

#template-content #wrapper-1 {
    width: 100% !important;
}

.badge-light-success {
    color: var(--success);
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
}

.badge-light-warning {
    color: var(--warning);
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
}

.badge-light-grey {
    color: var(--disabled);
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
}

.badge-light-danger {
    color: var(--error);
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
}

.badge-light-purple {
    color: white;
    background-color: #7239EA;
    font-size: 15px;
    font-weight: 400;
    border-radius: 4px;
}

.upload-img-drop-box {
    width: 200px;
    height: 200px;
    max-width: 100%;
    border: 2px dashed var(--primary);
    text-align: center;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.account-keyword .dl-input-textfield input {
    font-size: 16px !important;
}

.truncateSearchBar .MuiInputBase-input > div:first-child > p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    margin-top: 3px;
}

/*grapes css*/

.editor-template iframe {
    border: 0;
}

.zoomImg:hover {
    background-color: #0073ff !important;
}

.img__wrap:hover .text-hover {
    visibility: visible;
    opacity: 1;
}

.text-hover {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    color: #3b97e3;
    visibility: hidden;
    opacity: 0;
    text-shadow: var(--error);
    box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    transition: all .2s ease-in-out;
}

.font-red {
    text-shadow: var(--error);
}

.header-count-blocks {
    background: white 0 0 no-repeat padding-box;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 1;
}

* ::-webkit-scrollbar {
    width: 7px;
    height: 4px;
}

* ::-webkit-scrollbar-track {
    background: #f1f1f1 !important;
}

* ::-webkit-scrollbar-thumb {
    background: #656565 !important;
    border-radius: 10px !important;
}

* ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

@media (max-width: 768px) {
    .cell {
        width: 100% !important;
        display: block !important;
    }
}

@media (max-width: 768px) {
    .navbar-burger {
        display: block !important;
    }
}

@media (max-width: 768px) {
    .navbar-items-c {
        width: 100% !important;
    }

    .navbar-menu {
        width: 100%;
    }
}

.drawer-width .MuiDrawer-paper {
    width: 25%;
    height: calc(100% - 80px);
}

.drawer-width .MuiDrawer-paper {
    width: 25%;
    height: 100%;
    top: 41px;
}

.webkit-inline {
    display: -webkit-inline-flex !important;
}

.btn-group-templates .MuiButton-root {
    color: #f6f6f6;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px 10px;
}

.btn-group-templates .MuiButton-root:hover {
    color: rgba(0, 0, 0, 0.4);
    background: #ffffff;
}

.btn-group-templates .MuiButton-outlined:hover {
    /*border-width: 2px;*/
}

.btn-group-templates .MuiButton-outlined {
    border-color: #f6f6f6;
}

/*image Editor css*/
.filter-color-item {
    display: none !important;
}

.tie-btn-rotate {
    display: none !important;
}

.tie-btn-shape {
    display: none !important;
}

.tie-btn-icon {
    display: none !important;
}

.tie-btn-mask {
    display: none !important;
}

.dropdownMenu {
    padding: 8px 0 !important;
    font-size: 16px !important;
}

.dropdownMenu:hover {
    background-color: white !important;
    text-decoration: underline !important;
}

/*20-3*/
/*.gjs-am-assets-cont {*/
/*    height: 300px !important;*/
/*    padding: 0 !important*/
/*}*/
/*20-3*/
/*.tui-image-editor-canvas-container {*/
/*    max-width: 75% !important;*/
/*    max-height: 50% !important;*/
/*}*/
/*20-3*/
/*.tui-image-editor {*/
/*    width: 50% !important;*/
/*    !*left: 0 !important;*!*/
/*}*/
/*20-3*/
/*.tui-image-editor-canvas-container .lower-canvas .upper-canvas {*/
/*    max-width: 310px !important;*/
/*}*/
/*20-3*/

.legendColorBox {
    width: 15px;
    height: 15px;
    background: red;
}

/*Template overlay style start*/

.template-overlay .container {
    position: relative;
}

.template-overlay .box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.7;
    background: #0057e3;
}

.template-overlay .overlay {
    z-index: 999;
    background: transparent;
}

/*Template overlay style end*/

.usersCountViewBlackBoxes {
    width: 150px;
    background-color: #42424a

}

.badgeFont {
    font-size: 10px;
}

.panel {
    background: white 0 0 no-repeat padding-box;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 1;
}

.content-card {
    border-radius: 8px;
    opacity: 1;
    padding: 12px;
    height: 100%;
    min-height: 80px;
}

.bg-gray {
    background-color: #959494;
}

.bg-color-total {
    background-color: #2376D4;
}

.bg-color-opened {
    background-color: #4997ED;
}

.bg-color-closed {
    background-color: #7CB1EB;
}

.content-card-count {
    letter-spacing: 0;
    color: var(--secondary-text);
    font: bold 36px Barlow;
    font-weight: 600;
    justify-content: center;
}

.content-card-label {
    letter-spacing: 0;
    color: var(--secondary-text);
    font: bold 18px Barlow;
    font-weight: 600;
    justify-content: center
}

.screenHeader {
    font-weight: 500;
    font-style: normal;
    font-size: 30px;
    color: var(--tertiary);
    opacity: 1;
    letter-spacing: 0;
    padding: 15px 15px 0 15px;
    background-color: var(--white);
    display: flex;
}

.panel {
    background: var(--white) 0 0% no-repeat padding-box;
    box-shadow: 0 0 6px #00000029;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    opacity: 1;
}

.panelHeader {
    font-weight: 600;
    color: #707070;
    font: 18px Barlow
}


table.wrapperclass table {
    table-layout: fixed;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;

}

.header-title {
    font-weight: 500; /*var(--font-weight-semibold)*/
    font-style: normal; /*var(--font-style-normal)*/
    font-size: 20px;
    color: var(--tertiary);
    margin-top: -1px;
}

.wrapperclass {
    min-height: 100vh !important;
    padding-top: 0.001em !important;
    margin-left: auto !important;
    margin-right: auto !important;
    table-layout: fixed !important;
    max-width: 500px !important;
    width: 500px !important;
    border-collapse: collapse !important;
}

@media (max-width: 650px) {
    .gjs-fonts:before {
        font-size: 2em !important;
    }

}

@media (max-width: 575px) {
    .panel {
        background: var(--white) 0 0% no-repeat padding-box;
        box-shadow: none;
        border: none;
        border-radius: 0;
        opacity: unset;
    }

}

@media (max-width: 992px) {
    .home-images:hover {
        text-decoration: none;
    }

    .gjs-fonts:before {
        font-size: 3.3em !important;
    }
}

.gjs-rte-action {
    border: 1px solid #d3d3d3 !important;
    color: #000 !important;
    margin: 2px !important;
    padding: 8px 12px !important;
    font-size: 16px !important;
    width: auto !important;
}

.gjs-rte-actionbar {
    flex-wrap: wrap !important;
    width: 490px;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;

}

.header-title {
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: var(--tertiary);
    margin-top: -1px;
}

.count-header {
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    opacity: 1;
    color: #8B8B8B;
    padding-bottom: 8px;
    padding-top: 8px;
}

.label {
    text-align: left;
    font-weight: 400;
    letter-spacing: 0;
    font-size: 16px;
    color: var(--light-gray);
}

.link-primary {
    color: #0073ff;
    font-size: 16px;
}

@media (max-width: 992px) {
    .pageContainer {
        padding: 0 15px 0 15px !important;
        background-color: white;
    }

    .page-container {
        padding: 0 15px 0 15px !important;
        background-color: white;
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
    }

    .screenHeader {
        font-weight: 500;
        font-style: normal;
        font-size: 24px;
        color: var(--tertiary);
        opacity: 1;
        letter-spacing: 0;
        padding: 15px 15px 0 15px;
        background-color: white;
        display: flex;
    }

    .content-card {
        border-radius: 8px;
        opacity: 1;
        padding: 12px;
        height: 100%;
        min-height: 110px;
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px;
    }
}

@media (min-width: 992px) {
    .pageContainer {
        padding: 0 30px 0 30px !important;
        background-color: white;
    }

    .page-container {
        padding: 0 16px 0 16px !important;
        background-color: white;
    }

    .screenHeader {
        font-weight: var(--font-weight-semibold);
        font-style: var(--font-style-normal);
        font-size: 30px;
        color: var(--tertiary);
        opacity: 1;
        letter-spacing: 0;
        padding: 15px 30px 0 30px;
        background-color: var(--white);
        display: flex;
    }

    .content-card {
        border-radius: 8px;
        opacity: 1;
        padding: 12px;
        height: 100%;
        min-height: 80px;
    }
}

.panel-header {
    text-align: left;
    font: normal var(--font-weight-bold) 16px Barlow;
    letter-spacing: 0;
    color: #707070;
    opacity: 1;
    height: 50px !important;
    display: flex;
    align-items: center;
}

.right-border {
    border-right: 1px solid #dee2e6;
}

.bullet-dot {
    border: 1px solid var(--light-gray);
    border-radius: 50%;
    width: 5px !important;
    height: 5px;
    background-color: var(--light-gray);
}

.active-link .bullet-dot {
    border: 1px solid var(--primary);
    background-color: var(--primary);
}

.MuiPaper-root {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.dropdown-toggle::after {
    color: #276DBC;
    margin-right: 8px !important;
}

.table-div {
    display: table;
    caption-side: bottom;
    border-collapse: collapse;
    width: 100% !important;
    text-indent: initial;
    border-spacing: 2px;
    border-color: gray;
}

.text-success {
    color: var(--success);
}

.text-disable {
    color: var(--disabled);
}

.role-badge {
    border-radius: 4px;
    background-color: var(--disabled-text);
    color: var(--primary-text);
    font-size: var(--font-size-xxs);
}

/*new css as per design*/
#app-header-nav a {
    text-decoration: none;
    color: var(--black-text);
    font-size: 16px;
    height: 24px;
}

#app-header-nav a:hover {
    border-bottom: 2px solid #282828 !important;
}

#app-header-nav a.active {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #282828 !important;
}

/*#app-header-nav a.active{*/
/*    !*text-decoration: underline !important;*!*/
/*    color: #0007F3 !important;*/
/*    font-weight: 500;*/
/*    border-bottom: 2px solid #0007F3 !important;*/
/*}*/
#app-header-nav-mobile a {
    text-decoration: none;
    color: var(--black-text);
    font-size: 9px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex: 1;
}

#app-header-nav-mobile a.active {
    font-weight: 500;
    height: 100%;
}

#app-header-nav-mobile a > .horizontal-line {
    height: 3px;
    width: 100%;
    background-color: var(--white) !important;
    border-radius: 20px;
}

#app-header-nav-mobile a.active > .horizontal-line {
    height: 3px;
    width: 100%;
    background-color: var(--black) !important;
    border-radius: 20px;
}

.pagination-container .MuiTablePagination-selectLabel {
    margin-bottom: 0 !important;
}

.pagination-container .MuiTablePagination-displayedRows {
    margin-bottom: 0 !important;
}

.bar-chart-line canvas {
    height: 200px !important;
    widows: 100%;
}

.line-chart canvas {
    padding: 0 !important
}

.fundBar-chart-line canvas {
    height: 190px !important;
    widows:100%;
}

.resappbar-search-box-container > .dropdown {
    border: 1px solid var(--light-gray);
    border-radius: 25px !important;
    background-color: white;
    width: 100%;
    max-width: 1150px;
    display: flex;
    flex-grow: 1;
}

.resappbar-search-box-container .dropdown-toggle::after {
    display: none;
}

.resappbar-search-box-container .dropdown-menu.show {
    max-width: 1150px;
    max-height: 550px;
    overflow-y: auto;
    background-color: white;
}

.resappbar-search-box-container > .show dropdown. > button {
    border-radius: 25px !important;
}

.resappbar-search-box-container .btn-primary {
    --bs-btn-color: var(--black);
    --bs-btn-bg: none !important;
    --bs-btn-border-color: none !important;
    --bs-btn-hover-color: #fff;
    width: 100%;
}

.btn {
    --bs-btn-bg: transparent;
    --bs-btn-border-color: transparent;
    --bs-btn-border-radius: 0.375rem;
    --bs-btn-hover-border-color: transparent;
    background-color: transparent;
}

.resappbar-search-box-container .btn:hover {
    color: var(--black);
    background-color: transparent;
    border-color: var(--bs-btn-hover-border-color);
}

.resappbar-search-box-container .btn.show {
    color: var(--black);
    background-color: transparent;
}

.resappbar-search-box-container .dropdown-item:hover {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg);
}

.light-bg {
    background-color: rgba(249, 249, 251, 1);
}

.block-container {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.20);
}

.home-images {
    display: flex;
    flex-direction: column;
}

.home-images-name {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
}

.home-images:hover {
    text-decoration: underline;
}

.home-images > div {
    /*max-width: 320px;*/
    margin-bottom: 20px;
}

.fixedHeader {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    padding: 0;
}


#summaryTabs > div:first-child {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
}

#summaryTabsParent > div:first-child {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 20px;
    padding-bottom: 0px;
}

.btn-loader,
.btn-loader:after {
    border-radius: 50%;
    width: 10px;
    height: 10px;
}

.btn-loader {
    margin-top: 0;
    padding: 0;
    position: relative;
    animation: load8 0.8s infinite linear;
}

.MuiTablePagination-root .MuiTablePagination-toolbar .MuiInputBase-root {
    margin-right: 8px !important;
}

.MuiTablePagination-root .MuiTablePagination-toolbar .MuiTablePagination-actions {
    margin-left: 5px;
}

.MuiTablePagination-root .MuiTablePagination-toolbar .MuiTablePagination-actions button {
    padding: 0px !important;
}

#tableTabPanel .MuiBox-root {
    padding: 0px !important;
}

.resappbar-search-box-container-options {
    border: 1px solid var(--light-gray);
    border-radius: 7px !important;
    background-color: white;
    max-width: 1150px;
    max-height: calc(100vh - 190px);
    overflow-y: auto;
}

.search-tab {
    border: 1px solid var(--light-gray);
    border-radius: 7px !important;
}

.ulLine{
    padding-inline-start: 0 !important;
    margin-bottom: 0 !important;
}

.ulLine::-webkit-scrollbar{
    display: none;
}

.custom-underlined-text {
    text-decoration-line: none !important;  /* Adjust the distance between text and underline */
}

.borderLineBottom{
    min-height: 5px ;
    background-color: black;
}
.muiIconBtn{
    width: 40px;
    height: 40px;
}

.advanceSearchContainer{
    width: 100vw;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999
}

.badge-sm {
    min-height: 18px;
    padding-Top: 5px;
    padding-Bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px;
}

.badge-success {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    background-color: #029c48;
    border-radius: 4px;
}

.badge-danger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    color: #FFFFFF;
    background-color: #D72F2F;
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
}

.pageContainerWrapper{
    padding : 10px 30px 10px 30px !important;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    background-color: red !important;
}

.customCheckBoxStyle .MuiTypography-root {
    padding-left: 8px;
}

.customCheckBoxStyle .MuiTypography-root >span {
    padding-left: 0px !important;
}

.downloadButtonCss{
    border: 1px solid #282828;
    border-radius: 8px;
    display: flex;
    align-items: center;
    width: 36px;
    height: 36px;
    justify-content: center
}