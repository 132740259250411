
.custom-file-input {
    color: transparent;
    /*height: 200px;*/
    /*width: 500px !important;*/
    position: absolute;
    top: 437px;
    left: 614px;
}

.custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
}

.custom-file-input::before {
    content: 'Change Image';
    display: inline-block;
    color: black;
    background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid dodgerblue;
    padding: 5px 8px;
    outline: none;
    /*height: 200px;*/
    /*width: 500px !important;*/
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
    /*background-color: red;*/
}

.custom-file-input:hover::before {
    border-color: black;
}

.custom-file-input:active {
    outline: 0;
}

.custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.gjs-am-assets {
    height: 100% !important;
}

.gjs-mdl-dialog {
    width: 100% !important;
}

.upload-dialog {
    width: 50% !important;
}

.fa-arrow-up {
    display: none !important;
}


.custom-file-input-dropzone {
    color: transparent;
    /*height: 100%;*/
    width: 90% !important;
    height: 80%;
    border: 2px dashed #707070;
    padding: 20px 0 !important;
    margin: 0 26px;
}

.custom-file-input-dropzone::-webkit-file-upload-button {
    visibility: hidden;
}

.gjs-am-assets-header {
    padding: 0 !important;
    padding-bottom: 10px !important;
}

.custom-file-input-dropzone::before {
    content: 'Upload Image';
    display: inline-block;
    /*color: black;*/
    background: url(../img/default_image.jpg) no-repeat center top;
    /*border: 2px dashed #707070;*/
    outline: none;
    height: 100%;
    width: 100% !important;
    white-space: nowrap;
    /*max-height: 300px;*/
    -webkit-user-select: none;
    cursor: pointer;
    background-size: 120px;
    background-position-y: 40%;
    /*padding: 20px 0 !important;*/
    /*margin: 10px 0;*/
    /*border: 2px solid 003;*/
    /*text-shadow: 1px 1px #fff;*/
    /*font-weight: 700;*/
    /*font-size: 10pt;*/
    /*background-color: red;*/
}

.custom-file-input-dropzone:hover::before {
    border-color: black;
}

.custom-file-input-dropzone:active {
    outline: 0;
}

.gjs-am-add-asset .gjs-am-add-field {
    width: 70% !important;
}

.tui-image-editor-container {
    text-align: unset !important;
}

.tui-image-editor-menu-filter .tui-image-editor-main {
    text-align: unset !important;
}

.gjs-am-add-field {
    background-color: white !important;
}

.gjs-am-add-asset button {
    width: 24% !important;
}

.tui-image-editor-container .tui-image-editor-wrap .tui-image-editor-size-wrap .tui-image-editor-align-wrap {
    vertical-align: unset !important;
}

.tui-image-editor-header-buttons {
    display: none;
    height: 0 !important;
}

.gjs-am-asset-image {
    display: none;
}



.gjs-mdl-content {
    padding: 0 !important;
}

.tui-image-editor-container {
    background-color: #f5f5f5;
}

.tui-image-editor-container.left .tui-image-editor-main-container {
    background-color: #f5f5f5 !important;
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-style {
    margin-top: -24%;
}

.tui-image-editor-container.left .tui-image-editor-menu, .tui-image-editor-container.right .tui-image-editor-menu {
    background-color: #282828 !important;
}

/*20-3*/
/*.tui-image-editor-container .tui-image-editor {*/
/*    height: 271px !important;*/
/*}*/
/*20-3*/
.tui-image-editor-main-container {
    background-color: #f5f5f5 !important;
}


.tui-image-editor-submenu {
    padding-bottom: 70px;
}

.tui-image-editor-container .tui-image-editor-header {
    background-color: #282828 !important;
}

.tui-image-editor-container .tui-image-editor-main {
    top: 56px !important;
}

.tui-image-editor-main-container {
    background-color: #f5f5f5 !important;
}

.tui-image-editor-wrap {
    background-color: #f5f5f5 !important;
}


.tui-image-editor-partition {
    display: none !important;
}

.tui-image-editor-help-menu {
    display: none !important;
}

.tui-image-editor-header-logo {
    display: none !important;
}

.tui-image-editor-download-btn {
    display: none !important;
}

.tui-image-editor-load-btn {
    display: none !important;
}

.tui-image-editor-header-buttons {
    float: left !important;
    align-items: center;
    position: static;
    text-align: center;
}

.tui-image-editor-container.bottom .tui-image-editor-submenu > div {
    padding-bottom: 0 !important;
    padding-left: 5px !important;
    /*margin-top: 31px !important;*/
}

.editor-template body {
    /* min-height: calc(100vh - 150px) !important; */
    /*height: 92%;*/
    /*width: 95%;*/
    border: 0;
    overflow-x: hidden !important;
}

.grapes-template iframe body {
    overflow-x: hidden !important;
}


.gjs-clm-tag {
    color: white !important;
}


.gjs-blocks-c, .gjs-pn-panel, .editor-template .gjs-pn-views-container {
    background-color: whitesmoke !important;
}

.gjs-fonts::before {
    font-size: 4em;
    font-weight: 500;
}

.gjs-block-label {
    line-height: normal;
    font-size: 11.5px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    overflow: hidden;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
    font-weight: 600 !important;
}

/*.gjs-cv-canvas__frames {*/
/*  position: absolute;*/
/*  top: 8%!important;*/
/*  left: 2%!important;*/
/*  width: 96%!important;*/
/*  height: 90%!important;*/
/*}*/

.gjs-block {
    min-height: 100px !important;
    min-width: 28% !important;
    max-width: 28% !important;
    flex: 1 1 0%;
    padding: 10px 4px;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;

}

.gjs-cv-canvas {
    width: calc(75% - 40px) !important;
    background-color: transparent !important;
    height: calc(100% - 80px) !important;
    /*margin-top: 20px !important;*/
    /*margin-right: 10px !important;*/
    /*margin-left: 10px !important;*/
    margin: 20px !important;
    top: 23px !important;
    /*background-color: #ffffff;*/
    /*opacity: 1;*/
    /*background-image: linear-gradient(135deg, #f8f8f8 25%, transparent 25%), linear-gradient(225deg, #f8f8f8 25%, transparent 25%), linear-gradient(45deg, #f8f8f8 25%, transparent 25%), linear-gradient(315deg, #f8f8f8 25%, #ffffff 25%) !important;*/
    /*background-position: 12px 0, 12px 0, 0 0, 0 0;*/
    /*background-size: 12px 12px;*/
    /*background-repeat: repeat;*/
}

.gjs-editor {
    background-color: #ffffff;
    opacity: 1;
    background-image: linear-gradient(135deg, #f8f8f8 25%, transparent 25%), linear-gradient(225deg, #f8f8f8 25%, transparent 25%), linear-gradient(45deg, #f8f8f8 25%, transparent 25%), linear-gradient(315deg, #f8f8f8 25%, #ffffff 25%) !important;
    background-position: 12px 0, 12px 0, 0 0, 0 0;
    background-size: 12px 12px;
    background-repeat: repeat;
    height: 100% !important;
}


.gjs-pn-commands {
    width: 75% !important;
}

.gjs-pn-options {
    right: 25% !important;
    top: 0;
}

.gjs-pn-views-container, .gjs-pn-views {

    width: 25% !important;

}

img .gjs-plh-image {
    outline: 3px solid #0073ff !important;
}

.gjs-blocks-c {
    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    place-content: flex-start space-between;
    justify-content: unset;
    padding: 5px;
}

.gjs-editor-cont {
    width: 100%;
    height: calc(100vh - 96px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.gjs-two-color {
    color: #656565 !important;
}

.gjs-one-bg {
    background-color: #ffffff !important;
}

.gjs-four-color-h:hover {
    color: #0073ff !important;
}


.gjs-pn-btn.gjs-pn-active {
    border-color: rgba(0, 0, 0, .15);
    background-color: unset !important;
    box-shadow: 0 0 3px rgb(0 0 0 / 25%) inset;
}

.gjs-pn-btn {
    font-size: 16px !important;
}

.gjs-rte-action {
    padding: 8px 12px !important;
    font-size: 16px !important;
}

.gjs-toolbar-item {
    padding: 7px 7px !important;
    background-color: #3b97e3 !important;
    width: 26px;
    margin-right: 5px;
}

.gjs-toolbar {
    background-color: transparent !important;
}

.gjs-frame {
    /*background-color: #656565 !important;*/
    bottom: -35px !important;
    overflow-y: scroll !important;
    -webkit-box-shadow: inset 0 0 10px #ffffff;
    background-color: #656565;
    overflow-x: hidden;
    border-radius: 5px
}

.gjs-four-color {
    color: #0073ff !important;
}

.gjs-pn-panel.gjs-pn-panelView .gjs-pn-btn {
    /*background-color: #dddddd !important;*/
    /*color: #656565 !important;*/
    margin-right: 0;
    border-radius: 0;
    min-width: 40px;
}

.gjs-pn-panelView {
    position: absolute !important;
    left: 32.5% !important;
}

.textEditor > .rdw-editor-wrapper >.rdw-editor-toolbar{
    padding: 6px 5px !important;
}

/*TODO: add css with less lines*/

.rdw-editor-toolbar > .rdw-inline-wrapper > .rdw-option-wrapper > img,
.rdw-editor-toolbar > .rdw-list-wrapper > .rdw-option-wrapper > img ,
.rdw-editor-toolbar > .rdw-text-align-wrapper > .rdw-option-wrapper > img,
.rdw-editor-toolbar > .rdw-colorpicker-wrapper > .rdw-option-wrapper > img,
.rdw-editor-toolbar > .rdw-link-wrapper > .rdw-option-wrapper > img,
.rdw-editor-toolbar > .rdw-remove-wrapper > .rdw-option-wrapper > img,
.rdw-editor-toolbar > .rdw-history-wrapper > .rdw-option-wrapper > img
{
    margin: 8px !important;
}

.rdw-editor-toolbar > .rdw-inline-wrapper > .rdw-option-wrapper,
.rdw-editor-toolbar > .rdw-list-wrapper > .rdw-option-wrapper ,
.rdw-editor-toolbar > .rdw-text-align-wrapper > .rdw-option-wrapper,
.rdw-editor-toolbar > .rdw-colorpicker-wrapper > .rdw-option-wrapper,
.rdw-editor-toolbar > .rdw-link-wrapper > .rdw-option-wrapper,
.rdw-editor-toolbar > .rdw-remove-wrapper > .rdw-option-wrapper,
.rdw-editor-toolbar > .rdw-history-wrapper > .rdw-option-wrapper
{
    height: auto !important;
}
.textEditor > .rdw-editor-wrapper > .rdw-editor-main > .DraftEditor-root
> .DraftEditor-editorContainer > .notranslate{
    height: 45vh !important;
    margin-top: 20px !important;
    border-top: 2px solid #cdcdcd;
}
.gjs-editor-cont .sp-container button, .gjs-editor-cont .sp-container button:hover, .gjs-editor-cont .sp-container button:active{
    color: black !important;
}
.wrappertd{
    padding-bottom: 20px !important;
}
.public-DraftStyleDefault-block{
    margin:0px !important;
}
.rdw-option-active{
    background-color: var(--) !important;
    box-shadow: none !important;
}

.rdw-editor-toolbar > .rdw-link-wrapper > .rdw-link-modal {
    height: 235px !important;
}

.gjs-field{
    background-color: white !important;
    border: 1px solid #d2d2d2 !important;
    border-radius: 4px !important;
}

.gjs-sm-field.gjs-sm-composite, .gjs-sm-composite.gjs-clm-select, .gjs-sm-composite.gjs-clm-field{
    background-color: white !important;
    border: 1px solid #d2d2d2 !important;
    border-radius: 4px !important;
}
.gjs-sm-property__margin{
    margin-top: 16px !important;
}
.gjs-sm-property__padding{
    margin-top: 16px !important;
}
.gjs-sm-property__border-radius{
    margin-top: 16px !important;
}
.gjs-sm-property__border{
    margin-top: 16px !important;
}
.gjs-sm-clear{
    margin-top: -5px;
}
.gjs-radio-item{
    color: #666666;
    font-size: 12px;
    font-weight: 400;
}
